import { Col, Row } from 'antd'
import { useEffect, useState } from 'react'
import CSwitch from '../../../../components/Common/CSwitch/CSwitch'
import SectionLayout from '../../../stock/stockscreen/component/SectionLayout'

export interface SocieteGestionStateInterface {
  isPicking?: boolean
}

interface SocieteGestionSectionInterface {
  onChangeData?: (newValue: SocieteGestionStateInterface) => void
  initData?: SocieteGestionStateInterface
}

const SocieteGestion = (props: SocieteGestionSectionInterface) => {
  const { onChangeData = () => {}, initData } = props
  const [formState, setFormState] = useState<SocieteGestionStateInterface>({
    isPicking: false,
  })

  useEffect(() => {
    if (!initData) return
    setFormState(initData)
  }, [initData])

  useEffect(() => {
    onChangeData(formState)
  }, [formState])

  const onChangeSwitch = (value: boolean, field: string) => {
    setFormState({
      ...formState,
      [field]: value,
    })
  }

  return (
    <SectionLayout title="Gestion">
      <form>
        <Row>
          <Col xl={{ span: 8 }}>
            <Row>
              <Col xl={{ span: 16 }} className="form-label">
                Préparation palette après picking
              </Col>
              <Col xl={{ span: 8 }}>
                <CSwitch
                  name="isPicking"
                  onChange={(value) => onChangeSwitch(value, 'isPicking')}
                  checked={formState.isPicking}
                  className="custom-switch"
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </form>
    </SectionLayout>
  )
}

export default SocieteGestion
