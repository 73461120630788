import { LeftOutlined } from '@ant-design/icons'
import { Button, Col, Row } from 'antd'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../app/store'
import CustomPopup from '../../../../components/Common/CustomPopup/CustomPopup'
import Loading from '../../../../components/Common/Loading/Loading'
import { MODE } from '../../../../enum/mode'
import { popupType } from '../../../../enum/popupType'
import { focusById } from '../../../../hook/usePressTab'
import { formatDateTime } from '../../../../utils'
import '../Societe.scss'
import useSocieteHook from '../useSocieteHook'
import SocieteGestion from './SocieteGestion'
import SocieteMainSection from './SocieteMainSection'

interface SocieteProps {
  mode: MODE
}

const SocieteComponent = (props: SocieteProps) => {
  const { mode } = props
  const societeKey = useSelector((state: RootState) => state.selector).data
    .societe
  const { t } = useTranslation()

  const {
    mainSectionData,
    gestionData,
    errorMainSection,
    isLoading,
    societeData,
    onCancelModal,
    onChangeDataMainSection,
    onCheckValidMainSection,
    onChangeDataGestion,
    onSubmit,
    deleteSingleSociete,
    openPopupDelete,
    setOpenPopupDelete,
  } = useSocieteHook()

  useEffect(() => {
    // default focus
    focusById(societeKey['nom'])
  }, [])

  return (
    <div className="create-section">
      {isLoading && <Loading />}
      <CustomPopup
        title={t('confirmDelete')}
        type={popupType.DELETE}
        isOpen={openPopupDelete}
        onConfirm={deleteSingleSociete}
        onClose={() => setOpenPopupDelete(false)}
      >
        <p className="text-lg text-[#505050] my-10">{`${t('sureToDelete')} ${t(
          'company'
        )}?`}</p>
      </CustomPopup>
      <Row className="bd-bot-page-title pb-3.5" style={{ height: '10%' }}>
        <Col className="flex items-center">
          <div
            className="bg-[#F3F3F3] px-2 py-1 rounded-md cursor-pointer"
            onClick={onCancelModal}
          >
            <LeftOutlined />
          </div>
          <span className="modal-title-heading-1 mx-3">
            {mode === MODE.CREATE ? t('creation') : t('edition')} :
          </span>
          <span className="text-primary text-2xl capitalize">
            {t('company')}
          </span>
        </Col>
      </Row>
      {mode === MODE.EDIT && (
        <div className="flex justify-between italic text-[#808080] text-sm">
          <div className="text-[#848484]">
            Création :{' '}
            {formatDateTime(societeData?.created_at || 0, t('time-format'))}
          </div>
          <div className="text-[#848484]">
            Modification :{' '}
            {formatDateTime(societeData?.updated_at || 0, t('time-format'))}
          </div>
        </div>
      )}
      <div className="h-4"></div>
      <div className="overflow-y-auto main-content h-screen">
        <SocieteMainSection
          onChangeData={onChangeDataMainSection}
          onCheckValid={onCheckValidMainSection}
          mainSectionError={errorMainSection}
          initData={mainSectionData}
          mode={mode}
        />
        <div className="h-4"></div>
        <SocieteGestion
          onChangeData={onChangeDataGestion}
          initData={gestionData}
        />
      </div>
      <div className="fixed-bottom flex justify-between items-center pr-0">
        {!societeData?.block_del ? (
          <button
            id={societeKey['Supprimer']}
            data-next-id={societeKey['Sauvegarder']}
            onClick={() => setOpenPopupDelete(true)}
            className="supprimer border-red border-solid bg-white hover:text-red text-red text-lg font-semibold pl-12 pr-5 py-1 rounded-lg cursor-pointer  relative"
          >
            {t('deletion')}
          </button>
        ) : (
          <div></div>
        )}
        <Button
          disabled={isLoading}
          id={societeKey['Sauvegarder']}
          data-next-id={societeKey['nom']}
          data-previous-id={societeKey['entrepot']}
          className={`btn-submit-modal text-btn-submit-last-modal mr-3`}
          onClick={onSubmit}
        >
          {t('save')}
        </Button>
      </div>
    </div>
  )
}

export default SocieteComponent
