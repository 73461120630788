import { Col, Row } from 'antd'
import { useEffect, useRef, useState } from 'react'
import CSelect from '../../../../components/Common/CSelect/CSelect'
import { COption } from '../../../../components/Common/CSelect/model/COption'
import Loading from '../../../../components/Common/Loading/Loading'
import GenKeyHook from '../../../../hook/genKeyHook'
import societeApi from '../../../../http/societeApi'
import { Entrepot, EntrepotSociete } from '../../../../models'
import { CommonError } from '../../../../models/common/error'
import { removeTones } from '../../../../utils'
import {
  CInput,
  NameAndValue,
} from '../../../stock/stockscreen/component/Wrapper'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../app/store'
import { MODE } from '../../../../enum/mode'
import _ from 'lodash'
import { useSearchParams } from 'react-router-dom'

export interface SocieteMainSectionStateInterface {
  id?: string
  nom?: string
  code?: string
  warehouse_ids?: string[]
  warehouse_company?: EntrepotSociete[]
  warehouse_company_id_delete?: string[]
}

interface SocieteMainSectionInterface {
  /**
   * update data of societe main section data changed
   * @param value changed data
   * @returns void
   */
  onChangeData?: (newValue: SocieteMainSectionStateInterface) => void
  /**
   * check valid when onclick sauvegader
   * @param value is valid
   * @returns void
   */
  onCheckValid?: (isValid: boolean) => void
  /**
   * array of societe main section errors
   */
  mainSectionError?: CommonError[]
  initData?: SocieteMainSectionStateInterface
  mode: MODE
}

const SocieteMainSection = (props: SocieteMainSectionInterface) => {
  const societeKey = useSelector((state: RootState) => state.selector).data
    .societe
  const {
    onChangeData = () => {},
    mainSectionError = [],
    onCheckValid = () => {},
    initData,
    mode,
  } = props
  const [formState, setFormState] = useState<SocieteMainSectionStateInterface>({
    id: '',
    nom: '',
    code: '',
    warehouse_ids: [],
  })
  const [isValidNom, setIsValidNom] = useState<boolean>(false)
  const [isValidCode, setIsValidCode] = useState<boolean>(false)
  const [entrepotSelect, setEntrepotSelect] = useState<COption[]>([])
  const [entrepotFromApi, setEntrepotFromApi] = useState<EntrepotSociete[]>([])
  const { code } = GenKeyHook()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const limitEntrepotNumber = 10000
  const initDataRef = useRef<SocieteMainSectionStateInterface | undefined>(
    undefined
  )
  const [searchParams] = useSearchParams()
  const societeId = searchParams.get('societe-id')

  useEffect(() => {
    if (mode === MODE.CREATE)
      setFormState({
        ...formState,
        code,
      })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code])

  useEffect(() => {
    if (
      !_.isEqual(initData, initDataRef.current) &&
      initData &&
      mode === MODE.EDIT
    ) {
      initDataRef.current = initData
      setFormState(initData)
    }
  }, [initData])

  useEffect(() => {
    if (mode === MODE.CREATE) getAllEntrepotToCreate()
    else if (mode === MODE.EDIT && societeId) getAllEntrepotToEdit(societeId)
  }, [])

  const getAllEntrepotToEdit = async (id: string) => {
    setIsLoading(true)
    try {
      const response = (
        await societeApi.getAllEntrepotToUpdateSociete({
          id,
        })
      ).data.entry
      console.log(response.filter((el) => el.block_del))

      let entrepotResult: COption[] = response.map((warehouse) => ({
        uuid: warehouse.warehouse_id,
        value: warehouse.warehouse_id,
        label: warehouse.warehouse_code_nom || 'undefined',
        childOptions: [],
        level: 0,
        show: true,
        selected: !!warehouse.company_code_nom,
        disabled: warehouse.block_del,
      }))
      setEntrepotFromApi(response)
      setEntrepotSelect(entrepotResult)
      setIsLoading(false)
    } catch (e) {
      setIsLoading(false)
    }
  }

  const getAllEntrepotToCreate = () => {
    setIsLoading(true)
    societeApi
      .getAllEntrepot({
        limit: limitEntrepotNumber,
        offset: 0,
      })
      .then((res) => {
        const warehouses: Entrepot[] = res.data.entry
        let entrepotResult: COption[] = warehouses.map((warehouse) => {
          let entrepot: COption = {
            uuid: warehouse.ID,
            value: warehouse.ID,
            label: warehouse.code_nom || 'undefined',
            childOptions: [],
            level: 0,
            show: true,
          }

          return entrepot
        })
        setEntrepotSelect(entrepotResult)

        setIsLoading(false)
      })
      .catch((e) => {
        console.log(e)
        setIsLoading(false)
      })
  }

  useEffect(() => {
    onChangeData(formState)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState])

  useEffect(() => {
    setIsValidNom(!!formState.nom)
    setIsValidCode(!!formState.code)
    onCheckValid(isValidCode && isValidNom)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState, isValidCode, isValidNom])

  const onChangeInput = ({ name, value }: NameAndValue) => {
    setFormState({
      ...formState,
      [name || '']: value,
    })
  }

  const onChangeSociete = (options: COption[]) => {
    const warehouseIds = options
      .filter((option) => option.selected)
      .map((option) => option.value)

    //filter warehouse-company selected and remove block_del
    const warehouseCompany = entrepotFromApi
      .filter((el) => warehouseIds.includes(el.warehouse_id))
      .map(({ block_del, ...rest }) => rest)
    //list ids of  warehouse-company selected
    const warehouseCompanyIds = warehouseCompany.map((el) => el.id)
    //list ids of  warehouse-company deleted
    const deletedWarehouseCompanyIds = entrepotFromApi
      .filter((el) => !!el.id && !warehouseCompanyIds.includes(el.id))
      .map((el) => el.id)

    setFormState({
      ...formState,
      warehouse_ids: warehouseIds,
      warehouse_company: warehouseCompany,
      warehouse_company_id_delete: deletedWarehouseCompanyIds,
    })
  }

  return (
    <div className="section-layout" style={{ height: 150 }}>
      {isLoading && <Loading />}
      <div className="flex m-5">
        <div className="flex mr-20 items-center">
          <div className="form-label require-filed mr-5">Nom</div>
          <CInput
            id={societeKey['nom']}
            nextId={societeKey['code']}
            name="nom"
            value={formState.nom}
            maxLength={32}
            required
            className="custom-input"
            onChange={onChangeInput}
            errorMessageForce={
              mainSectionError.find((item) => item.name === 'nom')?.errorMessage
            }
          />
        </div>
        <div className="flex mr-20 items-center">
          <div className="form-label require-filed mr-5">Code</div>
          <CInput
            id={societeKey['code']}
            previousId={societeKey['nom']}
            nextId={societeKey['entrepot']}
            name="code"
            value={formState.code}
            maxLength={10}
            required
            className="custom-input"
            format={removeTones}
            onChange={onChangeInput}
            errorMessageForce={
              mainSectionError.find((item) => item.name === 'code')
                ?.errorMessage
            }
          />
        </div>
        <div className="flex relative">
          <Row>
            <Col>
              <div className="form-label">Entrepôt(s)</div>
            </Col>
            <Col className="absolute left-24 z-10">
              <CSelect
                id={societeKey['entrepot']}
                previousId={societeKey['code']}
                nextId={societeKey['Sauvegarder']}
                onChange={onChangeSociete}
                optionsAreaMaxHeight={300}
                options={entrepotSelect}
                width={380}
                height={100}
              />
            </Col>
          </Row>
        </div>
      </div>
    </div>
  )
}

export default SocieteMainSection
